import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ISimplifiedBranchCashReconciliation } from "../../../../shared/types";
import { useBranchHook, useCollaboratorHook } from "../../../../hooks";
import { getMexicoTime } from "../../../../shared/helpers";

interface Props {
  reconciliations: ISimplifiedBranchCashReconciliation[];
  linkTo: "branch" | "reconciliation";
}

export const SimplifiedBranchCashReconciliationTable: React.FC<Props> = ({
  reconciliations,
  linkTo,
}) => {
  const { getCollaboratorById } = useCollaboratorHook();
  const { getBranchNameById } = useBranchHook();

  const navigate = useNavigate();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {/* <TableCell>MxDate</TableCell> */}
            <TableCell>Date</TableCell>
            <TableCell>Branch</TableCell>
            <TableCell>Cashier</TableCell>
            <TableCell align="right">Start Cash</TableCell>
            <TableCell align="right">Qvet Cash</TableCell>
            <TableCell align="right">End Cash</TableCell>
            <TableCell align="right">Transfer</TableCell>
            <TableCell align="right">Closing Cash</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reconciliations.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => {
                if (linkTo === "branch") {
                  navigate(
                    `/dashboard/simplified-branch-cash-reconciliation/branch/${getBranchNameById(
                      row.branchId
                    )}`
                  );
                } else {
                  navigate(
                    `/dashboard/simplified-branch-cash-reconciliation/${row.id}`
                  );
                }
              }}
              sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.04)" },
              }}
            >
              <TableCell>
                {getMexicoTime(row.transactionDate).format("DD/MM/YYYY HH:mm")}
              </TableCell>
              {/* <TableCell>
                {format(new Date(row.transactionDate), "dd/MM/yyyy HH:mm")}
              </TableCell> */}
              <TableCell>{getBranchNameById(row.branchId)}</TableCell>
              <TableCell>
                {getCollaboratorById(row.cashierId)?.col_code}
              </TableCell>
              <TableCell align="right">${row.cashInDrawerStart}</TableCell>
              <TableCell align="right">${row.qvetCashInDrawer}</TableCell>
              <TableCell align="right">${row.cashInDrawerEnd}</TableCell>
              <TableCell align="right">
                ${Number(row.cashTransfer).toFixed(2)}
              </TableCell>
              <TableCell align="right">
                ${Number(row.closingCash).toFixed(2)}
              </TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
