import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { roleTypes } from "../../../../shared/types/types";
import {
  Business,
  Badge,
  CleaningServices,
  Grid3x3,
  AccessTime,
  LineStyle,
  PermIdentity,
  WorkOutline,
  MiscellaneousServicesOutlined,
  Article,
  WorkspacePremium,
  BeachAccess,
  Task,
  EditNote,
  WorkHistory,
  MonetizationOn,
  NotificationsNone,
  Inventory,
  Description,
  Schedule,
  DesignServices,
  LocalAtm,
  Payment,
} from "@mui/icons-material";
import { List } from "@mui/material";
import { setMenuState } from "../../../../actions/dbUiActions";
import { SidebarLink } from "../SidebarLink";
import { useAppSelector } from "../../../../hooks";
import { RootState } from "../../../../store/store";

export const Sidebar: React.FC = () => {
  const dispatch = useDispatch();
  const { menuState, isSmallScreen } = useSelector(
    (state: RootState) => state.dbUi
  );
  const { role } = useAppSelector((state) => state.authReducer.user!);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    setIsAuthorized(role === roleTypes.admin);
  }, [role]);

  const handleNavLinkClick = () => {
    if (isSmallScreen) {
      dispatch(setMenuState("close"));
    }
  };

  const linkItems = [
    { link: "/dashboard/Home", icon: <LineStyle />, label: "Home" },
    {
      link: "/dashboard/collaborators",
      icon: <Badge />,
      label: "Colaboradores",
    },
    {
      link: "/dashboard/cleanups/summary",
      icon: <CleaningServices />,
      label: "Limpieza",
    },
    { link: "/dashboard/rfc", icon: <Grid3x3 />, label: "RFC" },
    {
      link: "/dashboard/users",
      icon: <PermIdentity />,
      label: "Usuarios",
    },
    {
      link: "/dashboard/authLogs",
      icon: <AccessTime />,
      label: "Log de acceso",
    },
    {
      link: "/dashboard/activity-register",
      icon: <WorkOutline />,
      label: "Registro de actividades",
    },
    {
      link: "/dashboard/misc",
      icon: <MiscellaneousServicesOutlined />,
      label: "Misc",
    },
    {
      link: "/dashboard/documentation",
      icon: <Article />,
      label: "Documentación",
    },
    { link: "/dashboard/fcm", icon: <WorkspacePremium />, label: "FCM" },
    {
      link: "/dashboard/time-off-requests",
      icon: <BeachAccess />,
      label: "Time off requests",
    },
    { link: "/dashboard/tasks", icon: <Task />, label: "Tasks" },
    {
      link: "/dashboard/work-logs",
      icon: <EditNote />,
      label: "Work Logs",
    },
    {
      link: "/dashboard/attendance-records",
      icon: <WorkHistory />,
      label: "Attendance Records",
    },
    {
      link: "/dashboard/billing",
      icon: <MonetizationOn />,
      label: "Billing",
    },
    {
      link: "/dashboard/notifications",
      icon: <NotificationsNone />,
      label: "Notifications",
    },
    {
      link: "/dashboard/products",
      icon: <Inventory />,
      label: "Products",
    },
    {
      link: "/dashboard/suppliers",
      icon: <Business />,
      label: "Suppliers",
    },
    {
      link: "/dashboard/controlled-prescriptions",
      icon: <Description />,
      label: "Controlled Prescriptions",
    },
    {
      link: "/dashboard/work-shifts",
      icon: <Schedule />,
      label: "Turnos",
    },
    {
      link: "/dashboard/parameters",
      icon: <DesignServices />,
      label: "Parameters",
    },
    {
      link: "/dashboard/simplified-branch-cash-reconciliation",
      icon: <LocalAtm />,
      label: "Reconciliación de caja",
    },
    {
      link: "/dashboard/payrolls",
      icon: <Payment />,
      label: "Payrolls",
    },
  ];

  return (
    <List sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      {linkItems.map(({ link, icon, label }) => (
        <SidebarLink
          key={link}
          link={link}
          handleNavLinkClick={handleNavLinkClick}
          muiIcon={icon}
          label={label}
        />
      ))}
    </List>
  );
};
