import React from "react";
import { Route, Routes } from "react-router-dom";
import { PayrollsIndex } from ".";

export const PayrollsRouter = () => {
  return (
    <Routes>
      <Route index element={<PayrollsIndex />} />
      {/* <Route path="new" element={<PayrollNew />} />
      <Route path=":id" element={<PayrollDetails />} />
      <Route path="edit/:id" element={<PayrollEdit />} />
      <Route path="copy/:id" element={<EmploymentCopy />} /> */}
      {/* <Route path="edit" element={<SimplifiedBranchCashReconciliationEdit />} />
      {/* <Route path="edit" element={<SimplifiedBranchCashReconciliationEdit />} />
      <Route path="*" element={<SimplifiedBranchCashReconciliationIndex />} /> */}
    </Routes>
  );
};
