import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import dayjsWithPlugins from "../../config/dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import "dayjs/locale/es-mx";

dayjs.extend(isoWeek);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

// ===================================================
// SECTION
// ===================================================

// ---------------------------------------------------
//   Subsection
// ---------------------------------------------------

/* Group */

//***************************************************/

// =========================================
// STRING DATES
// =========================================

// 2025-01-21 > [2025-01-21, 2025-01-22, 2025-01-23, 2025-01-24, 2025-01-25, 2025-01-26, 2025-01-27]
export const getWeekDatesByDate = (date: string | Date): string[] => {
  // Parse the date as UTC and ensure the day starts at midnight in UTC
  const inputDate = dayjs.utc(date).startOf("day");

  // Get the start of the week (Monday, isoWeekday 1) in UTC
  const startOfWeek = inputDate.isoWeekday(1);

  // Generate an array with the dates of the entire week (from Monday to Sunday)
  const weekDates = Array.from({ length: 7 }, (_, index) =>
    startOfWeek.add(index, "day").format("YYYY-MM-DD")
  );

  return weekDates;
};

export const getWeekUtcDatesByDate = (date: string | Date): string[] => {
  const weekDates = getWeekDatesByDate(date);
  return weekDates.map((date) => dayjs.utc(date).toISOString());
};

// 2025-01-21T00:00:00Z > 2025-01-21
export const dateIntoStringDate = (date: Date | string) => {
  return dayjsWithPlugins(date).utc().format("YYYY-MM-DD");
};

export const getStringDateAsDDMMYYYY = (date: string) => {
  return dayjs.utc(date).format("DD-MM-YYYY");
};

export const getIsonStringDateMxByDateAndTime = (
  date: string,
  time: string
) => {
  return dayjs.tz(`${date}T${time}`, "America/Mexico_City").toISOString();
};

// =========================================
// FORMATS
// =========================================

// 2025-01-21T00:00:00Z > 21-Jan-2025
export const formatDate = (date?: string | Date) => {
  if (!date) return "N/A";
  return dayjs(date).format("DD-MMM-YYYY");
};

// 2025-01-21T00:00:00Z > Lun-21-Jan
export const formatDateToDateDay = (date?: string | Date) => {
  if (!date) return "N/A";
  const dayObj = dayjs.utc(date); // Use UTC
  const dayAbbr =
    dayObj.format("ddd").charAt(0).toUpperCase() +
    dayObj.format("ddd").slice(1, 3); // Get capitalized day abbreviation (e.g., "Lun")
  const formattedDate = dayObj.format("DD/MMM"); // Get date in format "30/Sep"

  return `${dayAbbr}-${formattedDate}`;
};

// 2025-01-21T00:00:00Z > M21
export const formatDateToAbbrDay = (dateString: string) => {
  const dayObj = dayjs.utc(dateString);
  const dayAbbr = dayObj.format("ddd").charAt(0);
  const dayOfMonth = dayObj.format("DD");

  return `${dayAbbr}${dayOfMonth}`;
};

// 2025-01-21T00:00:00Z > 2025-01-21
export const formatDateToUTC = (date?: string | Date | dayjs.Dayjs) => {
  if (!date) return "N/A";
  return dayjs.utc(date).format("YYYY-MM-DD");
};

// 2025-01-21T00:00:00Z > 12:00
export const formatTimeToLocalTime = (time?: string) => {
  if (!time) return "N/A";

  return dayjs(time).format("HH:mm");
};

// 2025-01-21T00:00:00Z > 2025-01-21 12:00
export const formatDatetime = (datetime?: string | Date | Dayjs) => {
  if (!datetime) return "N/A";
  return dayjs(datetime).format("YYYY-MM-DD HH:mm");
};

export const getDateFromDatetime = (datetime: string | Date | Dayjs) => {
  return dayjs(datetime).format("YYYY-MM-DD");
};

// 2025-01-21T00:00:00Z > Lunes, 21 de enero de 2024
export const formatDateToLongSpanish = (date: string | Date | Dayjs) => {
  const formatted = dayjs(date)
    .locale("es-mx")
    .format("dddd, D [de] MMMM [de] YYYY");
  console.log({ formatted });
  return formatted.charAt(0).toUpperCase() + formatted.slice(1);
};

// =========================================
// BOOLEAN
// =========================================

export const isSaturdayOrSunday = (date: string): boolean => {
  const day = dayjs.utc(date).day();
  return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
};

export const isSunday = (date: string): boolean => {
  return dayjs.utc(date).day() === 0;
};

export const compareDates = (
  date1?: string | Date | Dayjs,
  date2?: string | Date | Dayjs
) => {
  if (!date1 || !date2) return false;
  let newDate1: dayjs.Dayjs = parseToDayjs(date1);
  let newDate2: dayjs.Dayjs = parseToDayjs(date2);

  return newDate1.isSame(newDate2);
};

export const isDatetimeAfter = (
  datetime1: string | Date | dayjs.Dayjs,
  datetime2: string | Date | dayjs.Dayjs
) => {
  return dayjs(datetime1).isAfter(dayjs(datetime2));
};

export const isDatetimeBefore = (
  datetime1: string | Date | dayjs.Dayjs,
  datetime2: string | Date | dayjs.Dayjs
) => {
  return dayjs(datetime1).isBefore(dayjs(datetime2));
};

export const areDatesConsecutive = (dates: Date[]): boolean => {
  if (dates.length <= 1) return true;

  // Sort dates chronologically
  const sortedDates = [...dates].sort((a, b) => a.getTime() - b.getTime());

  // Check if each date is exactly 1 day after the previous
  for (let i = 1; i < sortedDates.length; i++) {
    const prevDate = dayjsWithPlugins(sortedDates[i - 1]);
    const currDate = dayjsWithPlugins(sortedDates[i]);

    if (currDate.diff(prevDate, "day") !== 1) {
      return false;
    }
  }

  return true;
};

export const isDateInRange = (
  date: Dayjs,
  startDate: Dayjs,
  endDate: Dayjs
) => {
  return dayjs(date).isSameOrAfter(startDate) && dayjs(date).isBefore(endDate);
};

// =========================================
// RETURN: Date
// =========================================

export const getMonday = (date?: Date | dayjs.Dayjs | string): Date => {
  let inputDate: dayjs.Dayjs;

  // Parse the input into a dayjs object in UTC
  if (dayjs.isDayjs(date)) {
    inputDate = date.utc();
  } else if (typeof date === "string" || date instanceof Date) {
    inputDate = dayjs.utc(date);
  } else {
    inputDate = dayjs.utc();
  }

  // Handle invalid date
  if (!inputDate.isValid()) {
    throw new Error("Invalid date provided");
  }

  // Adjust to the Monday of the current or previous week, setting time to 00:00:00 UTC
  const monday = inputDate.isoWeekday(1).startOf("day");

  // Return as a native Date object in UTC
  return monday.toDate();
};

export const getSunday = (date?: Date | dayjs.Dayjs | string): Date => {
  const monday = getMonday(date);
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  return sunday;
};

export const getDatetimeFromTime = (time: string) => {
  const [hour, minute] = time.split(":").map(Number);
  return dayjs.utc().set("hour", hour).set("minute", minute);
};

export const getUtcDateRangeForHalfMonth = (date: dayjs.Dayjs) => {
  const firstHalfMonthDate = getFirstHalfMonthUtcDate(date);
  const lastHalfMonthDate = getLastHalfMonthUtcDate(date);

  return {
    startDate: firstHalfMonthDate,
    endDate: lastHalfMonthDate,
  };
};

export const getUtcDateRangeForExtendedHalfMonth = (date: dayjs.Dayjs) => {
  const firstHalfMonthDate = getFirstHalfMonthUtcDate(date);
  const lastHalfMonthDate = getLastHalfMonthUtcDate(date);
  const mondayFirstHalfMonthDate = getMonday(firstHalfMonthDate.toISOString());
  const sundayLastHalfMonthDate = getSunday(lastHalfMonthDate.toISOString());

  return {
    startDate: mondayFirstHalfMonthDate,
    endDate: sundayLastHalfMonthDate,
  };
};

export const getUtcDateRangeForMonth = (date: dayjs.Dayjs) => {
  const firstMonthDate = getFirstUtcDateOfMonth(date);
  const lastMonthDate = getLastUtcDateOfMonth(date);
  return {
    startDate: firstMonthDate,
    endDate: lastMonthDate,
  };
};

export const getUtcDateRangeForExtendedMonth = (date: dayjs.Dayjs) => {
  const firstMonthDate = getFirstUtcDateOfMonth(date);
  const lastMonthDate = getLastUtcDateOfMonth(date);
  const mondayFirstMonthDate = getMonday(firstMonthDate.toISOString());
  const sundayLastMonthDate = getSunday(lastMonthDate.toISOString());
  return {
    startDate: mondayFirstMonthDate,
    endDate: sundayLastMonthDate,
  };
};

export const getUtcDateRangeForQuarter = (date: dayjs.Dayjs) => {
  const firstQuarterDate = getFirstUtcDateOfQuarter(date);
  const lastQuarterDate = getLastUtcDateOfQuarter(date);
  return {
    startDate: firstQuarterDate,
    endDate: lastQuarterDate,
  };
};

export const getUtcDateRangeForExtendedQuarter = (date: dayjs.Dayjs) => {
  const firstQuarterDate = getFirstUtcDateOfQuarter(date);
  const lastQuarterDate = getLastUtcDateOfQuarter(date);
  const mondayFirstQuarterDate = getMonday(firstQuarterDate.toISOString());
  const sundayLastQuarterDate = getSunday(lastQuarterDate.toISOString());
  return {
    startDate: mondayFirstQuarterDate,
    endDate: sundayLastQuarterDate,
  };
};

export const getUtcDateRangeForYear = (date: dayjs.Dayjs) => {
  const firstYearDate = getFirstUtcDateOfYear(date);
  const lastYearDate = getLastUtcDateOfYear(date);
  return {
    startDate: firstYearDate,
    endDate: lastYearDate,
  };
};
export const getUtcDateRangeForExtendedYear = (date: dayjs.Dayjs) => {
  const firstYearDate = getFirstUtcDateOfYear(date);
  const lastYearDate = getLastUtcDateOfYear(date);
  const mondayFirstYearDate = getMonday(firstYearDate.toISOString());
  const sundayLastYearDate = getSunday(lastYearDate.toISOString());
  return {
    startDate: mondayFirstYearDate,
    endDate: sundayLastYearDate,
  };
};

// =========================================
// RETURN: Dayjs
// =========================================

// ---------------------------------------------------
//   parsing
// ---------------------------------------------------

export const parseToDayjs = (dateInput: string | Date | Dayjs): Dayjs => {
  if (dayjs.isDayjs(dateInput)) {
    return dateInput; // Already a dayjs object
  } else if (dateInput instanceof Date) {
    return dayjs(dateInput); // Convert Date object to dayjs
  } else if (typeof dateInput === "string") {
    if (!isNaN(Date.parse(dateInput))) {
      return dayjs(dateInput); // Parse ISO string as-is
    } else {
      return dayjs(`${dateInput}T00:00:00Z`).utc(); // Parse non-ISO string as UTC midnight
    }
  } else {
    throw new Error("Invalid date input type");
  }
};

// ---------------------------------------------------
//   UTC
// ---------------------------------------------------

export const convertMxDateToUtc = (date: string) => {
  return dayjs.tz(date, "America/Mexico_City").utc();
};

// 1 or 16
export const getFirstHalfMonthUtcDate = (
  date?: string | Date | dayjs.Dayjs
) => {
  // Use current date in UTC if date is undefined
  const dateObj = date ? dayjs.utc(date).local() : dayjs.utc().local();

  const monthDate = dateObj.date();

  // Return the 1st or the 16th depending on the day of the month in local time
  return monthDate < 16
    ? dayjs.utc(dateObj.year() + "-" + (dateObj.month() + 1) + "-01") // Set to the 1st of the month
    : dayjs.utc(dateObj.year() + "-" + (dateObj.month() + 1) + "-16"); // Set to the 16th of the month
};

// 16 or 31
export const getLastHalfMonthUtcDate = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  // Get the input date as local time
  const localDate = date ? dayjs(date) : dayjs();

  // Get the corresponding UTC date
  const utcDate = localDate.utc();

  // Get the current day in local time
  const dayOfMonth = localDate.date();
  const monthOfYear = localDate.month(); // 0-indexed (0 = January, 1 = February, etc.)

  // Debugging logs

  // Check if the local date is in the first half of the month (1-15)
  if (dayOfMonth >= 1 && dayOfMonth <= 15) {
    // Return the 15th of the current month in UTC
    return utcDate.set("month", monthOfYear).set("date", 15).startOf("day");
  } else {
    // If the day is 16 or greater, return the last day of the current month in UTC
    console.log("Returning last day of the current month in UTC");
    return utcDate.endOf("month").startOf("day");
  }
};

export const getFirstUtcDateOfMonth = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  // If no date is provided, use the current date
  const inputDate = date ? dayjs(date) : dayjs();

  // Create a UTC Day.js object for the first day of the month
  return dayjs.utc(`${inputDate.year()}-${inputDate.month() + 1}-01`);
};

export const getLastUtcDateOfMonth = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const localDate = date ? dayjs(date) : dayjs();

  const utcDate = localDate.utc();

  // Get the current day in local time
  const dayOfMonth = localDate.date();
  const monthOfYear = localDate.month();

  return utcDate
    .set("month", monthOfYear)
    .set("date", dayOfMonth)
    .endOf("month");
};

export const getFirstUtcDateOfYear = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  return dayjs.utc(`${inputDate.year()}-01-01`);
};

export const getLastUtcDateOfYear = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  return getFirstUtcDateOfYear(inputDate.add(1, "year"));
};

export const getFirstUtcDateOfQuarter = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  const month = inputDate.month();
  let quarterStartMonth;

  if (month < 3) quarterStartMonth = 0; // Jan, Feb, Mar
  else if (month < 6) quarterStartMonth = 3; // Apr, May, Jun
  else if (month < 9) quarterStartMonth = 6; // Jul, Aug, Sep
  else quarterStartMonth = 9; // Oct, Nov, Dec

  const year = inputDate.year();
  return dayjs.utc().year(year).month(quarterStartMonth).date(1).startOf("day");
};

export const getLastUtcDateOfQuarter = (
  date?: string | Date | dayjs.Dayjs | null
) => {
  const inputDate = date ? dayjs(date) : dayjs();
  const month = inputDate.month();
  const year = inputDate.year();

  let quarterEndMonth;
  if (month < 3) quarterEndMonth = 2; // Last day of March
  else if (month < 6) quarterEndMonth = 5; // Last day of June
  else if (month < 9) quarterEndMonth = 8; // Last day of September
  else quarterEndMonth = 11; // Last day of December

  return dayjs
    .utc()
    .year(year)
    .month(quarterEndMonth)
    .endOf("month")
    .startOf("day");
};

export const getCurrentUtcDate = () => {
  return dayjs().utc().startOf("day");
};

export const getCurrentUcdDate = () => {
  const currentDate = dayjs();
  const localDate = currentDate.format("YYYY-MM-DD");
  const newUtcDateWithLocalDate = dayjs.utc(`${localDate}T00:00:00Z`);

  return newUtcDateWithLocalDate;
};

// YYYY-MM-DD to UTC midnight
export const getUtcMidnightDayjs = (dateString: string | Date | Dayjs) => {
  const inputDayjs = dayjs(dateString);

  const newDate = dayjs(`${inputDayjs.format("YYYY-MM-DD")}T00:00:00Z`).utc();

  return newDate;
};

export const convertDateAndTimeToMxDayjs = (
  date: dayjs.Dayjs,
  time: string
) => {
  // Convert the dayjs date to UTC and get the date portion directly as a string
  const utcDateString = date.utc().format("YYYY-MM-DD");

  // Combine the UTC date with the provided time
  const combinedDateTime = `${utcDateString}T${time}:00`;

  // Interpret the combined date-time as Mexico City time, then convert to UTC
  return dayjs.tz(combinedDateTime, "America/Mexico_City").utc();
};

export const getUtcStartOfDay = (date: string) => {
  return dayjs(`${date}T00:00:00Z`);
};

export const adjustUtcToLocalDate = (date: dayjs.Dayjs | Date) => {
  const offsetMinutes = dayjs().utcOffset();

  // Convert to Dayjs if it's a Date object
  const dayjsDate = dayjs.isDayjs(date) ? date : dayjs(date);
  const adjustedDateTime = dayjsDate.subtract(offsetMinutes, "minute");

  return adjustedDateTime.startOf("day");
};

// ---------------------------------------------------
//   Get multiple dates
// ---------------------------------------------------

export const getAllMondaysFromDates = (startDate: Dayjs, endDate: Dayjs) => {
  const mondays = [];

  for (
    let date = startDate;
    date.isBefore(endDate);
    date = date.add(1, "day")
  ) {
    if (date.day() === 1) {
      mondays.push(date);
    }
  }
  return mondays;
};

// ---------------------------------------------------
//   Get dates
// ---------------------------------------------------

export const getMondayFromStartDate = (startingDate: string | Date | Dayjs) => {
  const dayjsDate = dayjs(startingDate);
  return dayjsDate.startOf("week").add(1, "day");
};

export const getFirstDayOfMonth = (
  date: Dayjs,
  useMexicoTime: boolean = false
) => {
  const currentTimeZone = dayjs.tz.guess(); // Guess user's current time zone
  const mexicoTimeZone = "America/Mexico_City";
  const localTimeZone = currentTimeZone;
  const timeZoneToUse = useMexicoTime ? mexicoTimeZone : localTimeZone;
  return dayjs.tz(date, timeZoneToUse).startOf("month");
};

export const getLastDayOfMonth = (
  date: Dayjs,
  useMexicoTime: boolean = false
) => {
  const currentTimeZone = dayjs.tz.guess(); // Guess user's current time zone
  const mexicoTimeZone = "America/Mexico_City";
  const localTimeZone = currentTimeZone;
  const timeZoneToUse = useMexicoTime ? mexicoTimeZone : localTimeZone;
  return dayjs.tz(date, timeZoneToUse).endOf("month");
};

export const getFirstHalfOfTheMonthDate = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const adjustedDate = dayjs.tz(date, timeZoneToUse);
  return adjustedDate.date() < 16
    ? adjustedDate.startOf("month")
    : adjustedDate.startOf("month").add(15, "days");
};

export const getLastHalfOfTheMonthDate = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const adjustedDate = dayjs.tz(date, timeZoneToUse);

  if (adjustedDate.date() < 16) {
    return adjustedDate.startOf("month").add(14, "days").endOf("day");
  } else {
    return adjustedDate.endOf("month");
  }
};

export const getNextHalfOfTheMonthDate = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess();
  const adjustedDate = dayjs.tz(date, timeZoneToUse);

  // If we're in the first half (1-15), return the end of the current month
  if (adjustedDate.date() < 16) {
    return adjustedDate.endOf("month");
  }

  // If we're in the second half (16-end), return the 15th of next month
  return adjustedDate.add(1, "month").date(15).endOf("day");
};

export const getPreviousHalfOfTheMonthDate = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess();
  const adjustedDate = dayjs.tz(date, timeZoneToUse);

  // If we're in the first half (1-15), return the end of previous month
  if (adjustedDate.date() < 16) {
    return adjustedDate.subtract(1, "month").endOf("month");
  }

  // If we're in the second half (16-end), return the 15th of current month
  return adjustedDate.date(15).endOf("day");
};

export const getFirstDateOfQuarter = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const adjustedDate = dayjs.tz(date, timeZoneToUse);
  const quarterStartMonth = Math.floor(adjustedDate.month() / 3) * 3; // Calculate the first month of the quarter

  return adjustedDate.month(quarterStartMonth).startOf("month");
};

export const getLastDateOfQuarter = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstQuarterDate = getFirstDateOfQuarter(date, useMexicoTime);
  return firstQuarterDate.add(3, "months").subtract(1, "day").endOf("day");
};

export const getPreviousSunday = (date: Dayjs) => {
  // Si el día es domingo (0), retorna la misma fecha
  return date.day() === 0 ? date : date.subtract(date.day(), "day");
};

// ---------------------------------------------------
//   Get ranges
// ---------------------------------------------------

export const getDateRangeForHalfMonth = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstHalfMonthDate = getFirstHalfOfTheMonthDate(date, useMexicoTime);
  const lastHalfMonthDate = getLastHalfOfTheMonthDate(date, useMexicoTime);

  return {
    startDate: firstHalfMonthDate,
    endDate: lastHalfMonthDate,
  };
};

export const getDateRangeForQuarter = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstQuarterDate = getFirstDateOfQuarter(date, useMexicoTime);
  const lastQuarterDate = getLastDateOfQuarter(date, useMexicoTime);

  return {
    startDate: firstQuarterDate,
    endDate: lastQuarterDate,
  };
};

export const getDateRangeForYear = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const timeZoneToUse = useMexicoTime
    ? "America/Mexico_City"
    : dayjs.tz.guess(); // Guess user's current time zone

  const firstYearDate = dayjs.tz(date, timeZoneToUse).startOf("year");
  const lastYearDate = dayjs.tz(date, timeZoneToUse).endOf("year");

  return {
    startDate: firstYearDate,
    endDate: lastYearDate,
  };
};

// local or mexican dates

export const getDateRangeForMonth = (
  date: dayjs.Dayjs,
  useMexicoTime: boolean = false
) => {
  const firstMonthDate = getFirstDayOfMonth(date, useMexicoTime);
  const lastMonthDate = getLastDayOfMonth(date, useMexicoTime);

  return {
    startDate: firstMonthDate,
    endDate: lastMonthDate,
  };
};

export const getDayjsRangeFromDates = (
  startDate: Dayjs,
  endDate: Dayjs
): Dayjs[] => {
  const days = [];
  for (
    let date = startDate;
    date.isSameOrBefore(endDate);
    date = date.add(1, "day")
  ) {
    days.push(date);
  }
  return days;
};

// ---------------------------------------------------
//   Mexico dates
// ---------------------------------------------------

export const getMxDayjsDatetimeByDateAndTime = (date: string, time: string) => {
  return dayjs.tz(`${date}T${time}`, "America/Mexico_City");
};

export const getFirstMondayOfExtendedHalfWeekMx = (date: dayjs.Dayjs) => {
  const dayOfMonth = date.date();
  const targetDay = dayOfMonth <= 15 ? 1 : 16;
  const targetDate = date.date(targetDay);

  const dayOfWeek = targetDate.day();

  if (dayOfWeek === 1) {
    return targetDate;
  }

  const daysToMonday = (dayOfWeek + 6) % 7;
  const monday = targetDate.subtract(daysToMonday, "day");

  return monday;
};

export const getLastSundayOfExtendedHalfWeekMx = (date: dayjs.Dayjs) => {
  const dayOfMonth = date.date();

  const targetDay = dayOfMonth <= 15 ? 15 : date.daysInMonth();
  const targetDate = date.date(targetDay);

  const dayOfWeek = targetDate.day();
  if (dayOfWeek === 0) {
    return targetDate;
  }

  const daysToSunday = (7 - dayOfWeek) % 7; // Days to add to reach Sunday
  const sunday = targetDate.add(daysToSunday, "day");

  return sunday;
};

/*
  Mexico parsing
*/

export const convertUtcToMexicoUtc = (
  input: string | Date | dayjs.Dayjs
): dayjs.Dayjs => {
  const MEXICO_TIMEZONE = "America/Mexico_City";

  // Parse input as UTC
  let date = dayjs.utc(input);

  // Convert to Mexico City timezone correctly
  let mexicoTime = date.tz(MEXICO_TIMEZONE);

  // Get the UTC offset in hours
  const offset = mexicoTime.utcOffset();

  // Shift the UTC time by this offset to get the "Mexico time but in UTC"
  return date.add(offset, "minute");
};

export const convertUtcDateToMexicoTimeStartOfDay = (
  utcDate: Date | string | Dayjs
) => {
  // Convert UTC date to Mexico City timezone
  const dateString = dayjs.utc(utcDate).format("YYYY-MM-DD");
  // Create Mexico time at start of the same date
  return dayjs.tz(`${dateString}T00:00:00`, "America/Mexico_City");
};

export const toMexicoStartOfDay = (date: string | Date) => {
  const inputDateTime = dayjs(date);
  // spanish
  const spanishDate = dayjs(date).tz("Europe/Madrid").format("YYYY-MM-DD");
  // utc
  const utcDate = dayjs(date).tz("UTC").format("YYYY-MM-DD");

  const mexicoStartOfDay = getStartOfDayInTimezone(date, "America/Mexico_City");
  const spanishStartOfDay = getStartOfDayInTimezone(date, "Europe/Madrid");
  const utcStartOfDay = getStartOfDayInTimezone(date, "UTC");

  if (inputDateTime.isSame(mexicoStartOfDay, "day")) {
    return mexicoStartOfDay;
  }

  if (inputDateTime.isSame(spanishStartOfDay, "day")) {
    return dayjs.tz(spanishDate, "America/Mexico_City").startOf("day");
  }

  if (inputDateTime.isSame(utcStartOfDay, "day")) {
    return dayjs.tz(utcDate, "America/Mexico_City").startOf("day");
  }

  return utcStartOfDay;
};

export const transformDayjsToMexicoStartOfDay = (date: Dayjs) => {
  return toMexicoStartOfDay(date.toDate());
};

const getStartOfDayInTimezone = (date: string | Date, timezone: string) => {
  const localDate = dayjs(date).tz(timezone).format("YYYY-MM-DD");
  return dayjs(`${localDate}T00:00:00`).tz(timezone).startOf("day");
};

export const getLocalTimezone = () => {
  return dayjs.tz.guess();
};

export const getMexicoTime = (date: string | Date | Dayjs) => {
  return dayjs.tz(date, "America/Mexico_City");
};

export const convertToMexicoTimeInUtc = (input: string | Date | Dayjs) => {
  const MEXICO_TIMEZONE = "America/Mexico_City";

  // Detect system timezone
  const localTimezone = dayjs.tz.guess();

  // Parse the input date and assume it's in UTC
  let date = dayjs(input).utc();

  if (localTimezone === MEXICO_TIMEZONE) {
    return date; // Already in Mexico time, return as-is
  }

  // Convert UTC to Mexico time, then return it as UTC with shifted time
  const mexicoTime = date.tz(MEXICO_TIMEZONE, true);
  return mexicoTime.utc();
};

// =========================================
// RETURN: number
// =========================================

export const minutesBetweenDatetimes = (
  datetime1: string | Date | dayjs.Dayjs,
  datetime2: string | Date | dayjs.Dayjs
) => {
  return dayjs(datetime2).diff(dayjs(datetime1), "minutes");
};

export const calculateProportionalHours = (
  datetime1: string | Date | dayjs.Dayjs,
  datetime2: string | Date | dayjs.Dayjs
) => {
  return dayjs(datetime2).diff(dayjs(datetime1), "minutes") / 60;
};

// =========================================
// RETURN: void
// =========================================

export const testPerformance = (numExecutions = 2000) => {
  const startTime = performance.now(); // Record start time

  const testDate = new Date(); // Test with the current date

  for (let i = 0; i < numExecutions; i++) {
    toMexicoStartOfDay(testDate); // Call the function
  }

  const endTime = performance.now(); // Record end time
  const duration = endTime - startTime; // Calculate the time taken
  console.log(
    `Time taken for ${numExecutions} executions: ${duration.toFixed(2)} ms`
  );
};
