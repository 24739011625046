import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EmploymentService } from "../../../../../services";
import { EmploymentResponse } from "../../../../../shared/types";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Chip,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { CustomAvatar } from "../../../../../components/avatar/CustomAvatar";

import dayjs from "dayjs";
import { formatCurrency } from "../../../../../utils/formatters";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAuthHook, useCollaboratorHook } from "../../../../../hooks";
import { useJobsHook } from "../../../../../shared/hooks";
import { fireSwalError } from "../../../../../helpers";
import { fireSwalConfirmation } from "../../../../../helpers/utilities";

export const EmploymentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const service = new EmploymentService();

  const [employment, setEmployment] = useState<EmploymentResponse | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { getCollaboratorById } = useCollaboratorHook();
  const { currentUserId, isAdminOrManager } = useAuthHook();
  const { getJobTitleById } = useJobsHook();

  useEffect(() => {
    if (!id) return;
    setIsLoading(true);
    service
      .getById(id)
      .then(({ data }) => setEmployment(data))
      .finally(() => setIsLoading(false));
  }, [id]);

  const isAuthorized =
    isAdminOrManager || currentUserId === employment?.collaboratorId;

  useEffect(() => {
    if (!isLoading && employment?.id && !isAuthorized) {
      fireSwalError("You are not authorized to view this employment").then(
        () => {
          navigate("/dashboard/collaborators/employments");
        }
      );
    }
  }, [isLoading, employment, isAuthorized, navigate]);

  const handleDeleteEmployment = (employmentId: string) => {
    if (!isAdminOrManager) return;
    fireSwalConfirmation(
      "Are you sure you want to delete this employment?"
    ).then((result: boolean) => {
      if (result) {
        service.delete(employmentId).then(() => {
          navigate("/dashboard/collaborators/employments");
        });
      }
    });
  };

  if (!employment) return null;

  const collaborator = getCollaboratorById(employment.collaboratorId);

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h5">Employment Details</Typography>
        {isAdminOrManager && (
          <Stack direction="row" spacing={1}>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                navigate(
                  `/dashboard/collaborators/employments/edit/${employment.id}`
                )
              }
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="info"
              size="small"
              onClick={() =>
                navigate(
                  `/dashboard/collaborators/employments/copy/${employment.id}`
                )
              }
            >
              <ContentCopyIcon />
            </IconButton>
            <IconButton
              color="error"
              size="small"
              onClick={() => handleDeleteEmployment(employment.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
      </Stack>
      {!isAuthorized ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          {/* Header Info */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <CustomAvatar collaborator={collaborator!} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6">
                    {collaborator?.first_name} {collaborator?.last_name} -{" "}
                    {collaborator?.col_code}
                  </Typography>
                  <Typography color="text.secondary">
                    {getJobTitleById(employment.jobId)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    label={employment.isActive ? "Active" : "Inactive"}
                    color={employment.isActive ? "success" : "default"}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Basic Information */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Basic Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Start Date</Typography>
                  <Typography>
                    {dayjs(employment.employmentStartDate).format(
                      "MMM DD, YYYY"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">End Date</Typography>
                  <Typography>
                    {employment.employmentEndDate
                      ? dayjs(employment.employmentEndDate).format(
                          "MMM DD, YYYY"
                        )
                      : "Present"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Weekly Hours</Typography>
                  <Typography>{employment.weeklyHours}h/week</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Payment Type</Typography>
                  <Chip size="small" label={employment.paymentType} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Compensation Details */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Compensation Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Fixed Income</Typography>
                  <Typography>
                    {formatCurrency(employment.fixedIncome)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Minimum Income</Typography>
                  <Typography>
                    {formatCurrency(employment.minimumOrdinaryIncome)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Base Position Income
                  </Typography>
                  <Typography>
                    {formatCurrency(employment.fixedIncomeByPosition)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Additional Fixed
                  </Typography>
                  <Typography>
                    {formatCurrency(employment.additionalFixedIncome)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Bonuses */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Bonuses & Support
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Seniority Bonus
                  </Typography>
                  <Typography>
                    {employment.seniorityBonusPercentage * 100}%
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Commission Bonus
                  </Typography>
                  <Typography>
                    {employment.comissionBonusPercentage * 100}%
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">Degree Bonus</Typography>
                  <Typography>
                    {formatCurrency(employment.degreeBonus)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary">
                    Reception Bonus
                  </Typography>
                  <Typography>
                    {formatCurrency(employment.receptionBonus)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Averages */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3, height: "100%" }}>
              <Typography variant="h6" gutterBottom>
                Income Averages
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography color="text.secondary">
                    Contribution Base Salary
                  </Typography>
                  <Typography>
                    {formatCurrency(employment.contributionBaseSalary)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="text.secondary">
                    Average Ordinary Income
                  </Typography>
                  <Typography>
                    {formatCurrency(employment.averageOrdinaryIncome)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="text.secondary">
                    Average Integrated Income
                  </Typography>
                  <Typography>
                    {formatCurrency(employment.averageIntegratedIncome)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="text.secondary">
                    Average Commission Income
                  </Typography>
                  <Typography>
                    {formatCurrency(employment.averageComissionIncome)}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Extra Compensations */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Compensaciones Extra
              </Typography>
              {employment.extraCompensations &&
              employment.extraCompensations.length > 0 ? (
                <Box sx={{ overflowX: "auto" }}>
                  <Grid container spacing={2}>
                    {employment.extraCompensations.map((comp, index) => (
                      <Grid item xs={12} key={index}>
                        <Paper
                          variant="outlined"
                          sx={{ p: 2, backgroundColor: "background.default" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                              <Typography color="text.secondary">
                                Nombre
                              </Typography>
                              <Typography>{comp.name}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <Typography color="text.secondary">
                                Descripción
                              </Typography>
                              <Typography>{comp.description || "-"}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                              <Typography color="text.secondary">
                                Monto
                              </Typography>
                              <Typography>
                                {formatCurrency(comp.amount)}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                              <Typography color="text.secondary">
                                Tipo de Ingreso
                              </Typography>
                              <Chip size="small" label={comp.incomeType} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                              <Typography color="text.secondary">
                                Relacionado a Asistencia
                              </Typography>
                              <Chip
                                size="small"
                                color={
                                  comp.attendanceRelated ? "success" : "default"
                                }
                                label={comp.attendanceRelated ? "Sí" : "No"}
                              />
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Typography color="text.secondary">
                  No hay compensaciones extra registradas
                </Typography>
              )}
            </Paper>
          </Grid>

          {/* Other Deductions */}
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Otras Deducciones
              </Typography>
              {employment.otherDeductions &&
              employment.otherDeductions.length > 0 ? (
                <Box sx={{ overflowX: "auto" }}>
                  <Grid container spacing={2}>
                    {employment.otherDeductions.map((deduction, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Paper
                          variant="outlined"
                          sx={{ p: 2, backgroundColor: "background.default" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography color="text.secondary">
                                Nombre
                              </Typography>
                              <Typography>{deduction.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography color="text.secondary">
                                Monto
                              </Typography>
                              <Typography>
                                {formatCurrency(deduction.amount)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ) : (
                <Typography color="text.secondary">
                  No hay deducciones adicionales registradas
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
