import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";

import {
  useAppDispatch,
  useAppSelector,
  useAuthHook,
  useCollaboratorHook,
} from "../../../hooks";

export const PayrollsIndex = () => {
  // TODO: if is not manager, redirect to own payroll
  // Todo fetch employments
  // Todo: fetch payrolls
  // TODO: set the date
  const dispatch = useAppDispatch();
  const { payrolls, isLoading } = useAppSelector((state) => state.payrolls);
  const { employments, isLoading: isLoadingEmployments } = useAppSelector(
    (state) => state.employments
  );
  const { activeCollaboratorsIds } = useCollaboratorHook();

  const { isAdminOrManager, currentUserId } = useAuthHook();
  console.log({ isAdminOrManager });

  useEffect(() => {
    // dispatch(EmploymentThunks.fetchItems(query));
  }, []);
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
        <Typography variant="h4">Payrolls</Typography>
      </Box>
    </>
  );
};
