import { createAsyncThunk } from "@reduxjs/toolkit";
import { PayrollService } from "../../services";
import { createCRUDThunks, handleAsyncThunkError } from "../../shared/helpers";
import {
  AxiosParams,
  PayrollRequest,
  PayrollResponse,
} from "../../shared/types";

const service = new PayrollService();

const basicThunks = createCRUDThunks<PayrollResponse, PayrollRequest>({
  service,
  resourceName: "payrolls",
});

const getCollaboratorPayrollEstimate = createAsyncThunk(
  `payrolls/getCollaboratorPayrollEstimate`,
  async (
    {
      collaboratorId,
      params,
    }: { collaboratorId: string; params?: AxiosParams },
    { rejectWithValue }
  ) => {
    try {
      const data = await service.getCollaboratorPayrollEstimate(
        collaboratorId,
        params
      );
      return data.data;
    } catch (error) {
      return handleAsyncThunkError(error, rejectWithValue);
    }
  }
);

export const PayrollThunks = {
  ...basicThunks,
  getCollaboratorPayrollEstimate,
};
