export enum PayrollIncomeType {
  BASE_INCOME = "Base Income",
  COMPANY_BENEFIT = "Company Benefit",
}

export enum HRPaymentType {
  "HOURLY" = "HOURLY",
  "SALARY" = "SALARY",
  "INFORMAL" = "INFORMAL",
}

export enum PayrollStatus {
  Pending = "pending",
  Approved = "approved",
  Paid = "paid",
}
