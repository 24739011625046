import dayjs from "dayjs";
import { EXCEL_DATE_OFFSET } from "../shared/constants/datetimeConstants";
import { convertUtcToMexicoUtc, getMexicoTime } from "../shared/helpers";
import {
  ISimplifiedBranchCashReconciliationResponse,
  ISimplifiedBranchCashReconciliationRequest,
  ISimplifiedBranchCashReconciliation,
} from "../shared/types";
import { BaseService } from "./base.service";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export class SimplifiedBranchCashReconciliationService extends BaseService<
  ISimplifiedBranchCashReconciliationResponse,
  ISimplifiedBranchCashReconciliationRequest
> {
  constructor() {
    super("/simplified-branch-cash-reconciliation");
  }

  public transformEntityToRequest(
    entity: ISimplifiedBranchCashReconciliation
  ): ISimplifiedBranchCashReconciliationRequest {
    return {
      ...entity,
      transactionDate: entity.transactionDate.toISOString(),
      transactionDatetime: entity.transactionDatetime.toISOString(),
    };
  }

  public transformResponseToEntity(
    response: ISimplifiedBranchCashReconciliationResponse
  ): ISimplifiedBranchCashReconciliation {
    return {
      ...response,
      transactionDate: new Date(response.transactionDate),
      transactionDatetime: new Date(response.transactionDatetime),
      branchId: response.branchId.id,
      cashierId: response.cashierId.id ?? response.cashierId,
      createdAt: new Date(response.createdAt),
      updatedAt: new Date(response.updatedAt),
    };
  }

  public transformDataForExcelExport(
    data: ISimplifiedBranchCashReconciliation[]
  ): (string | number | undefined)[][] {
    const excelDateOffset = new Date(EXCEL_DATE_OFFSET).getTime();

    /*
      transactionDate
      branchName
      cashierName
      cashInDrawerStart
      qvetCashInDrawer
      cashInDrawerEnd
      cashTransfer
      closingCash
      id
    */

    return data.map((record) => {
      // {getMexicoTime(row.transactionDate).format("DD/MM/YYYY HH:mm")}
      const mexicoTimeOffset = dayjs(record.transactionDatetime).tz(
        "America/Mexico_City"
      );
      const mexicoTime = getMexicoTime(record.transactionDatetime);
      const mexicoTimeNew = convertUtcToMexicoUtc(record.transactionDatetime);

      console.log({
        originalTime: new Date(record.transactionDatetime).toISOString(),
        mexicoTimeOffset: new Date(mexicoTimeOffset.toISOString()),
        mexicoTime: new Date(mexicoTime.toISOString()),
        mexicoTimeNew: new Date(mexicoTimeNew.toISOString()),
      });

      return [
        (mexicoTimeNew.valueOf() - excelDateOffset) / (24 * 60 * 60 * 1000),
        record.branchId,
        record.cashierId,
        record.cashInDrawerStart,
        record.qvetCashInDrawer,
        record.cashInDrawerEnd,
        record.cashTransfer,
        record.closingCash,
        record.id,
      ];
    });
  }
}
