import { Grid, Typography } from "@mui/material";
import React from "react";
import { MenuRoute, ModuleMenu } from "../../../components";
import { PayrollsRouter } from "./PayrollsRouter";

export const PayrollsLayout = () => {
  const routes: MenuRoute[] = [
    { name: "List", path: "" },
    { name: "New", path: "new" },
  ];

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Employments</Typography>
      </Grid>
      <Grid item xs={12}>
        <ModuleMenu routes={routes} />
      </Grid>

      <Grid item xs={12}>
        <PayrollsRouter />
      </Grid>
    </Grid>
  );
};
