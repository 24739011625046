import * as XLSX from "xlsx";

export const exportToExcel = (
  headers: string[],
  data: any,
  filename: string
) => {
  const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, `${filename}.xlsx`);
};
