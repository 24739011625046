import { BaseService } from "./base.service";
import {
  PayrollFormValues,
  PayrollRequest,
  PayrollResponse,
  ICollaborator,
  IJob,
  AxiosParams,
  ApiResponse,
  PayrollEstimate,
  ApiResponseOne,
} from "../shared/types";
import dayjs from "dayjs";
import {
  COMMISION_BONUS_PERCENTAGE_PER_SEMESTER,
  DEGREE_BONUS,
  MAX_LEGAL_WEEKLY_HOURS,
  SENIORITY_BONUS_PERCENTAGE_PER_YEAR,
} from "../shared";
import hvpAuthenticatedApi from "../api/hvpAuthenticatedApi";

const API_URL = "/payrolls";
export class PayrollService extends BaseService<
  PayrollResponse,
  PayrollRequest
> {
  constructor() {
    super(API_URL);
  }

  public async getCollaboratorPayrollEstimate(
    collaboratorId: string,
    params?: AxiosParams
  ): Promise<ApiResponseOne<PayrollEstimate>> {
    const response = await hvpAuthenticatedApi.get<
      ApiResponseOne<PayrollEstimate>
    >(`${this.API_URL}/estimates/${collaboratorId}`, {
      params,
    });
    return response.data;
  }

  // public transformResponseToFormValues(
  //   response: PayrollResponse
  // ): PayrollFormValues {
  //   return {
  //     ...response,
  //     employmentStartDate: dayjs(response.employmentStartDate),
  //     employmentEndDate: response.employmentEndDate
  //       ? dayjs(response.employmentEndDate)
  //       : undefined,
  //   };
  // }

  // public transformFormValuesToRequest(
  //   formValues: PayrollFormValues
  // ): PayrollRequest {
  //   return {
  //     ...formValues,
  //     employmentStartDate: formValues.employmentStartDate.toISOString(),
  //     employmentEndDate: formValues.employmentEndDate?.toISOString(),
  //     createdAt:
  //       formValues.createdAt && new Date(formValues.createdAt).toISOString(),
  //     updatedAt:
  //       formValues.updatedAt && new Date(formValues.updatedAt).toISOString(),
  //   };
  // }

  // public recalculatePayroll({
  //   job,
  //   collaborator,
  //   employment,
  // }: RecalculatePayrollParams) {
  //   const { baseIncome, minimumIncome } = job;
  //   const { startDate, degree } = collaborator;
  //   const { weeklyHours, additionalFixedIncome } = employment;

  //   const workedYears = dayjs().diff(dayjs(startDate), "year");
  //   const workedSemesters = Math.floor(
  //     dayjs().diff(dayjs(startDate), "quarter") / 2
  //   );
  //   const seniorityBonusPercentage = Number(
  //     (workedYears * SENIORITY_BONUS_PERCENTAGE_PER_YEAR).toFixed(4)
  //   );
  //   const comissionBonusPercentage = Number(
  //     (workedSemesters * COMMISION_BONUS_PERCENTAGE_PER_SEMESTER).toFixed(4)
  //   );

  //   const hoursRatio = weeklyHours / MAX_LEGAL_WEEKLY_HOURS;

  //   const fixedIncomeByPosition = Number(
  //     (baseIncome * (1 + seniorityBonusPercentage) * hoursRatio).toFixed(4)
  //   );

  //   const minimumOrdinaryIncome = minimumIncome * hoursRatio;

  //   const degreeBonus = degree ? DEGREE_BONUS[degree] * hoursRatio : 0;

  //   const fixedIncome =
  //     Number(fixedIncomeByPosition) + Number(additionalFixedIncome);

  //   const result = {
  //     ...employment,
  //     seniorityBonusPercentage,
  //     comissionBonusPercentage,
  //     fixedIncomeByPosition,
  //     minimumOrdinaryIncome,
  //     degreeBonus,
  //     fixedIncome,
  //   };

  //   return result;
  // }

  // public async getPreviousPayroll(collaboratorId: string) {
  //   const employments = await this.getAll({
  //     collaboratorId,
  //     sort_by: "employmentStartDate",
  //     direction: "desc",
  //   });
  //   const employment = employments.data[0];
  //   if (!employment) return null;
  //   return this.transformResponseToFormValues(employment);
  // }
}

type RecalculatePayrollParams = {
  employment: PayrollFormValues;
  job: IJob;
  collaborator: ICollaborator;
};
