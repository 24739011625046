import { createSlice } from "@reduxjs/toolkit";
import { EmploymentResponse, PayrollResponse } from "../../shared/types";
import { PayrollThunks, TimeOffRequestThunks } from "../thunks";

interface State {
  payrolls: PayrollResponse[];
  isLoading: boolean;
}

const initialState: State = {
  payrolls: [],
  isLoading: true,
};

export const payrollSlice = createSlice({
  name: "payroll",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(PayrollThunks.fetchItems.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(PayrollThunks.fetchItems.fulfilled, (state, action) => {
      state.payrolls = action.payload;
      state.isLoading = false;
    });
    builder.addCase(PayrollThunks.fetchItems.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(PayrollThunks.createItem.fulfilled, (state, action) => {
      state.payrolls.push(action.payload);
    });
    builder.addCase(PayrollThunks.updateItem.fulfilled, (state, action) => {
      const index = state.payrolls.findIndex(
        (payroll) => payroll.id === action.payload.id
      );
      state.payrolls[index] = action.payload;
    });
    builder.addCase(PayrollThunks.deleteItem.fulfilled, (state, action) => {
      state.payrolls = state.payrolls.filter(
        (payroll) => payroll.id !== action.payload
      );
    });
  },
});

export default payrollSlice.reducer;
