import React, { useMemo } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ISimplifiedBranchCashReconciliation } from "../../../../shared/types";
import { useBranchHook } from "../../../../hooks";

interface Props {
  reconciliations: ISimplifiedBranchCashReconciliation[];
}

export const SimplifiedBranchCashReconciliationTotals = ({
  reconciliations,
}: Props) => {
  const { branches } = useBranchHook();

  const totals = useMemo(() => {
    const branchTotals = reconciliations.reduce(
      (acc, curr) => {
        if (!acc[curr.branchId]) {
          acc[curr.branchId] = {
            count: 0,
            startCash: null as number | null,
            transferSum: 0,
            lastClosing: curr.closingCash,
            oldestDate: curr.transactionDate,
            newestDate: curr.transactionDate,
          };
        }

        const record = acc[curr.branchId];
        record.count += 1;

        record.transferSum += curr.cashTransfer;

        // Track oldest record for start cash
        if (curr.transactionDate < record.oldestDate) {
          record.oldestDate = curr.transactionDate;
          record.startCash = curr.cashInDrawerStart;
        }

        // Track newest record for closing cash
        if (curr.transactionDate > record.newestDate) {
          record.newestDate = curr.transactionDate;
          record.lastClosing = curr.closingCash;
        }

        return acc;
      },
      {} as Record<
        string,
        {
          count: number;
          startCash: number | null;
          transferSum: number;
          lastClosing: number;
          oldestDate: Date;
          newestDate: Date;
        }
      >
    );

    return Object.entries(branchTotals).map(([branchId, totals]) => ({
      branchId,
      branchName:
        branches.find((b) => b.id === branchId)?.name || "Unknown Branch",
      ...totals,
    }));
  }, [reconciliations, branches]);

  return (
    <Paper sx={{ p: 2, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        Branch Totals
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Branch</TableCell>
              <TableCell align="right">Records</TableCell>
              <TableCell align="right">Initial Cash</TableCell>

              <TableCell align="right">Transfer Sum</TableCell>
              <TableCell align="right">Latest Closing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totals.map((row) => (
              <TableRow key={row.branchId}>
                <TableCell>{row.branchName}</TableCell>
                <TableCell align="right">{row.count}</TableCell>
                <TableCell align="right">
                  ${row.startCash?.toFixed(2) ?? "N/A"}
                </TableCell>

                <TableCell align="right">
                  ${row.transferSum.toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  ${row.lastClosing.toFixed(2)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                <strong>Total</strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  $
                  {totals
                    .reduce((sum, row) => sum + (row.startCash || 0), 0)
                    .toFixed(2)}
                </strong>
              </TableCell>

              <TableCell align="right">
                <strong>
                  $
                  {totals
                    .reduce((sum, row) => sum + row.transferSum, 0)
                    .toFixed(2)}
                </strong>
              </TableCell>
              <TableCell align="right">
                <strong>
                  $
                  {totals
                    .reduce((sum, row) => sum + row.lastClosing, 0)
                    .toFixed(2)}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
