import React, { useEffect, useState } from "react";
import { PeriodViewSelector } from "../../../../components/misc/PeriodViewSelector";
import { Dayjs } from "dayjs";
import { ISimplifiedBranchCashReconciliation } from "../../../../shared/types";
import { SimplifiedBranchCashReconciliationService } from "../../../../services";
import { SimplifiedBranchCashReconciliationTable } from "../components";
import {
  useAuthHook,
  useBranchHook,
  useCollaboratorHook,
} from "../../../../hooks";
import { SimplifiedBranchCashReconciliationTotals } from "../components/SimplifiedBranchCashReconciliationTotals";
import { Button, Grid } from "@mui/material";
import { exportToExcel } from "../../../../helpers/xlsxHelpers";

export const SimplifiedBranchCashReconciliationHistory = () => {
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [
    simplifiedBranchCashReconciliations,
    setSimplifiedBranchCashReconciliations,
  ] = useState<ISimplifiedBranchCashReconciliation[]>();
  const [sortBy, setSortBy] = useState<"branch" | "date">("date");

  const { getCollaboratorCodeById } = useCollaboratorHook();
  const { getBranchNameById } = useBranchHook();

  const { isAdmin } = useAuthHook();
  const service = new SimplifiedBranchCashReconciliationService();

  const handleExportToExcel = () => {
    const dataIdReplacement = simplifiedBranchCashReconciliations?.map(
      (reconciliation) => {
        return {
          ...reconciliation,
          branchId: getBranchNameById(reconciliation.branchId)!,
          cashierId: getCollaboratorCodeById(reconciliation.cashierId)!,
        };
      }
    );
    const transformedData = service.transformDataForExcelExport(
      dataIdReplacement || []
    );

    const headers = [
      "Datetime",
      "Branch",
      "Cashier",
      "Cash in drawer start",
      "Qvet cash in drawer",
      "Cash in drawer end",
      "Cash transfer",
      "Closing cash",
      "Id",
    ];

    exportToExcel(
      headers,
      transformedData,
      "Simplified Branch Cash Reconciliation"
    );
  };
  useEffect(() => {
    const fetchSimplifiedBranchCashReconciliations = async () => {
      if (startDate && endDate) {
        const service = new SimplifiedBranchCashReconciliationService();
        const data = await service.getAll({
          $transactionDatetime: `$range:${startDate.toISOString()}...${endDate.toISOString()}`,
        });
        const simplifiedBranchCashReconciliations = data.data.map((item) =>
          service.transformResponseToEntity(item)
        );
        const sorted =
          sortBy === "branch"
            ? simplifiedBranchCashReconciliations

                .sort(
                  (a, b) =>
                    new Date(b.transactionDatetime).getTime() -
                    new Date(a.transactionDatetime).getTime()
                )
                .sort((a, b) => a.branchId.localeCompare(b.branchId))
            : simplifiedBranchCashReconciliations.sort(
                (a, b) =>
                  new Date(b.transactionDatetime).getTime() -
                  new Date(a.transactionDatetime).getTime()
              );
        setSimplifiedBranchCashReconciliations(sorted);
      }
    };

    fetchSimplifiedBranchCashReconciliations();
  }, [startDate, endDate, sortBy]);

  return (
    <>
      <Grid container spacing={2} marginBottom={4}>
        <Grid item xs={12} md={8}>
          <PeriodViewSelector
            handleSetStartDate={setStartDate}
            handleSetEndDate={setEndDate}
            defaultView="half-month"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            onClick={() => setSortBy(sortBy === "branch" ? "date" : "branch")}
          >
            Sort by {sortBy === "branch" ? "date" : "branch"}
          </Button>
          <Button onClick={handleExportToExcel}>Export to Excel</Button>
        </Grid>
      </Grid>
      {/* <pre>{JSON.stringify(sortBy, null, 2)}</pre> */}

      <SimplifiedBranchCashReconciliationTotals
        reconciliations={simplifiedBranchCashReconciliations || []}
      />
      {simplifiedBranchCashReconciliations &&
      simplifiedBranchCashReconciliations.length > 0 ? (
        <SimplifiedBranchCashReconciliationTable
          reconciliations={simplifiedBranchCashReconciliations}
          linkTo="reconciliation"
        />
      ) : (
        <div>No data found</div>
      )}
      {isAdmin && (
        <>
          <pre>{JSON.stringify(startDate, null, 2)}</pre>
          <pre>{JSON.stringify(endDate, null, 2)}</pre>
          <pre>
            {JSON.stringify(simplifiedBranchCashReconciliations, null, 2)}
          </pre>
        </>
      )}
    </>
  );
};
